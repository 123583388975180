import React, { useEffect, useState } from 'react';

import "./Hub.css";
import "./Services.css";

import ViewModal from '../../modal/viewModal';
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';

import AddHubUserForm from '../../Component/Forms/addHubUserForm';
import { LinkButton, LinkButtonNS } from '../Admin/Dashboard/DashboardElement';
import Hubslider from '../../Component/Helpers/HubSlider';

//visible header that contains LOGO and Navigation Icon

import { courses } from '../../coursedata';

 function Hub() {
  const [show1,setShow1] = useState(false);
  const [loading,setLoading] = useState(false);
  const [ location,setLocation] = useState()
  const [data, setData] = useState()


 

  
     
  useEffect(()=>{
     const fetchLocationData =async()=>{
        setLoading(true)

        let locationData = []
       const q = query(collection(db, "branches"));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        locationData.push(doc.data())
      });
      
      setLocation(locationData);
      // splitsession(classesdata);
      setLoading(false)
      }
        fetchLocationData();
  },[])


  
  return(
        
        
<section className="hub " id="hub">


      <div className="p-auto">
          <div className=" m-5 m-sm-auto">
          {/* <div className="flyIn lineThree"></div> */}
          <h2 className="flyIn lineOne  py-3 text-info"><span>Join the Hub</span></h2>
          <div className='row col-11 mx-auto'>
          <Hubslider/>

          </div>

          
          <div className="flyIn container">
          

          
<div className="row  ">
      <div className="col col-md-6 col-sm-12 bg-light bg-opacity-2 pt-3 px-3 pt-md-5 px-md-5 text-start">
          <h5 className="display-7 ">JOIN THE HUB</h5>
          <p className="text-start">
          The end goal of learning is innovation. The very reason we don't just stop at training. We have created a space for our program's participants to innovate. 
          </p>
          <p>
          Our satisfaction lies in seeing mind-blowing robotics projects come from these next generation geniuses through our innovation hub. 
          </p>
          <p>
          The next big thing in the world of technology might just come from your ward and their like-minded innovative colleagues. This is our little Silicon Valley. We are starting them out early. For a discounted monthly subscription of GHS 300 (4 sessions) your ward can join the Nexbot Innovation Hub after completing our basic training programme. The Hub is open each and every Saturday between the hours of 11:30am - 1:00pm. Join the hub now!          </p>
      </div>
      <div className="col col-md-6 col-sm-12 bg-primary text-center pt-3 px-3 pt-md-5 px-md-5 py-5 text-white pricing">
        
    

					<div className="Row text-primary">
            <div className="card">
          {/* <div className="card-body">
          <h6 className="card-title  text-capitalize text-center">We are on a break! </h6>
						<h5 className="card-title  text-center text-capitalize ">we'll resume on</h5>

						<h6 className="card-price text-primary text-center ">{"14th January 2023"} <span className="period"></span></h6>   
						</div>
            </div> */}


					 <div className={0 === 0 ? 'card mb-5 mb-lg-0':'bg-warning card mb-5 mb-lg-0'} >
					<div className="card-body">
						<h6 className="card-title  text-capitalize text-center">Exciting Robotics Projects </h6>
						{/* <h5 className="card-title text-primary fw-bolder text-uppercase text-center">Enroll For a 23.5% Discount</h5>
						<h6 className="card-title text-center text-decoration-line-through text-danger"><span className="period">GH₵850</span></h6>    */}			
						 <hr/>
						<h5 className="card-title  text-center text-capitalize ">New Challenges each week </h5>
						<hr/>
						 <h5 className="card-title  text-center text-capitalize">Collaborative Learning </h5>
             <hr/>
						<h6 className="card-price text-primary text-center ">GH₵{courses.hub.price} <span className="period">per month</span></h6>   
						<ul className="fa-ul">
								
						</ul>
						<div className="d-grid">
            <button onClick={()=>setShow1(true)} className='btn btn-dark jnow mx-auto my-3'>Join the hub</button>
						
						
						</div>


					</div>
        </div> 
        </div>
        
      </div>

          <strong className='p-5 text-light'>Already in the hub?</strong>

          <br/>
          <LinkButtonNS  to="/hub" className='btn btn-light jnow mx-auto my-3'>login</LinkButtonNS>

      </div>
  </div>

          </div>
            <div className="flyIn lineFour">
            </div>
          </div>
    </div>

    <ViewModal
        handleClose={() => setShow1(false)}
        show={show1}
        button="close"
        title={"Hub Registration"}>
        <div className="row">	
        <AddHubUserForm />
      </div>
    </ViewModal>
    
  
</section>
    );
}
export default Hub;












