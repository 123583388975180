import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Spinner, Alert, Breadcrumb, Modal, Form, InputGroup } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../Component/Context/AuthContext';
import useAddCourse from '../Hooks/useAddCourse'; // Adjust the path accordingly
import AddClass from '../Hooks/useAddClass';

const AddClassForm = ({ closeModal, branch }) => {
  const [data, setData] = useState([]);
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success, setSuccess] = useState(false);
  const [courses, setCourses] = useState([]);
  const { userID } = useAuth();

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const coursesData = [];
      const q = query(collection(db, "courses"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        coursesData.push({ id: doc.id, ...doc.data() });
      });
      setCourses(coursesData);
      setData((data) => ({programId: 'dulxLFK5unfkodw3P5pw' , ...data })); // Set default to robotics
    } catch (error) {
      console.error("Error fetching courses:", error);
      setError("Failed to load courses data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const UpdateData =(item,value)=>{
    if(item === 'session'){

    
    if (value === 'morning') {
       setData(data => ({...data, 
          session:value,
          start : '9:30',
          startSubscript:'am',
          end : '11:00',
          endSubscript:'am',

      }))}
    if (value === 'midday') {
       setData(data => ({...data, 
          session:value,
          start : '11:30',
          startSubscript:'am',
          end : '1:00',
          endSubscript:'pm',
      }))}
    if (value === 'afternoon') {
       setData(data => ({...data, 
          session:value,
          start : '1:30',
          startSubscript:'pm',
          end : '3:00',
          endSubscript:'pm',

      }))}
    if (value === 'evening') {
       setData(data => ({...data, 
          session:value,
          start : '3:30',
          startSubscript:'pm',
          end : '5:00',
          endSubscript:'pm',
      })) }
      
    setFormError(formError => ({ ...formError, [item]: '' }));

    }
  else{
      setData(data => ({...data, 
          [item]:value}))
    setFormError(formError => ({...formError, [item]:''}))
}
}

  const eventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    UpdateData(nam, val);
  };

  const findFormErrors = () => {
    const newErrors = {}
    if (!data.session || data.session === '') newErrors.name = 'Cannot be blank!';
    else if (data.session.length > 100) newErrors.name = 'session is too long!';
    if (!data.date || data.date === '') newErrors.date = 'Add a valid date!';
    if (!data.datefororder || data.datefororder === '') newErrors.date = 'Add a valid date!';
    if (!data.minage || data.minage === '') newErrors.minage = 'select level!';
    return newErrors;
  };

  const Submit = async () => {
    const { error, success } = await AddClass(data, branch);
    setMainError(error);
    setSuccess(success);
    setData('');
    setLoading(false);
  };

  const Proceed = async () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setFormError(newErrors);
    } else {
      setLoading(true);
      Submit();
    }
  };

  const output = (
    <Form noValidate validated={validated} onSubmit={Proceed} inline>
      <Form.Group className="row" controlId="validationCustom04">
        <Form.Label className="col-3 align-bottom my-auto text-end">
          Session
        </Form.Label>
        <InputGroup className="form-input col">
          <Form.Control
            as="select"
            className="col"
            name="session"
            value={data.session}
            onChange={eventHandler}
            isInvalid={!!formError.session}
          >
            <option value=''>Select session</option>
            <option value='morning'>Morning</option>
            <option value='midday'>Midday</option>
            <option value='afternoon'>Afternoon</option>
            <option value='evening'>Evening</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>

      <Form.Group className="row" controlId="validationCustom01">
        <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
          Date in words
        </Form.Label>
        <InputGroup className="form-input col">
          <Form.Control
            className=""
            type="text"
            name="date"
            required
            value={data.date}
            onChange={eventHandler}
            isInvalid={!!formError.date}
          />
          <Form.Control.Feedback type="invalid">
            {formError.date}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group className="row" controlId="validationCustom01">
        <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
          Date from calendar
        </Form.Label>
        <InputGroup className="form-input col">
          <Form.Control
            className=""
            type="date"
            name="datefororder"
            required
            value={data.datefororder}
            onChange={eventHandler}
            isInvalid={!!formError.datefororder}
          />
          <Form.Control.Feedback type="invalid">
            {formError.date}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group className="row" controlId="validationCustom04">
        <Form.Label className="col-3 align-bottom my-auto text-end">
          Level
        </Form.Label>
        <InputGroup className="form-input col">
          <Form.Control
            as="select"
            className="col"
            name="minage"
            value={data.minage}
            onChange={eventHandler}
            isInvalid={!!formError.minage}
          >
            <option value=''>Select level</option>
            <option value={5}>Junior session (5+)</option>
            <option value={9}>Senior session (9+)</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>

      <Form.Group className="row" controlId="formProgramId">
        <Form.Label className="col-3 align-bottom my-auto text-end">Program ID</Form.Label>
        <InputGroup className="form-input col">
          <Form.Select
            name="programId"
            value={data.programId}
            onChange={eventHandler}
            isInvalid={!!formError.programId}
          >
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Form.Group>

      <Col className="text-center">
        <Button onClick={() => Proceed()} className="btn-success text-center">
          Proceed {(loading && !error) && <Spinner animation="border" variant="white" />}
        </Button>
      </Col>
    </Form>
  );

  return (
    <>
      {error && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
      {success && <Alert variant="success"><h3 className="mx-auto">{"Product added successfully"}</h3></Alert>}
      {(!error && !success) && output}
    </>
  );
};

export default AddClassForm;
