import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Spinner, Alert, Breadcrumb, Modal, Form } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Body } from '../Dashboard/DashboardElement';
import { collection, addDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../Component/Context/AuthContext';
import { programId } from '../../../coursedata';

const Courses = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newCourse, setNewCourse] = useState({
    duration: 16,
    name: "",
    price:0,
    programId: "",
  });
  

  const params = useParams();
  const courseId = params.courseId;

  const fetchLocationData = async () => {
    setLoading(true);
    try {
      const locationData = [];
      const q = query(collection(db, "courses"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        locationData.push(doc.data());
      });
      setLocation(locationData);
    } catch (error) {
      console.error("Error fetching location data:", error);
      setError("Failed to load course data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLocationData();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({
      ...newCourse,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Ensure the price is saved as a number
      const courseData = {
        ...newCourse,
        price: Number(newCourse.price), // Convert price to a number
      };
  
      // Add the document to Firestore
      const docRef = await addDoc(collection(db, "courses"), courseData);
  
      // Update the document to include the generated ID
      await updateDoc(docRef, { id: docRef.id });
  
      // Refresh the course list and close the modal
      fetchLocationData();
      handleCloseModal();
    } catch (error) {
      console.error("Error adding new course:", error);
      setError("Failed to add new course");
    }
    setLoading(false);
  };
  
  

  return (
    <Body>
      <Container className="m-2 mx-auto align-content-sm-center container-fluid">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/courses" }} active>Courses</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-3">
          <Col className="d-flex justify-content-between align-items-center">
            <div>
              {location && location.map((element) => (
                <Link
                  to={"/dashboard/courses/" + element.id}
                  key={element.id}
                  className={element.id === activeId ? 'branch-active text-primary fw-bolder text-uppercase m-2' : 'course-btn text-primary fw-bolder text-uppercase m-2'}
                  onClick={() => setActiveId(element.id)}
                >
                  {element.name}
                </Link>
              ))}
            </div>
            <Button variant="primary" onClick={handleShowModal}>
              <BsPlus size={20} /> Add Course
            </Button>
          </Col>
        </Row>

        {loading && <Spinner animation="grow" variant="success" />}
        {error && <Alert variant="danger">{error}</Alert>}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="formCourseName">
                <Form.Label>Course Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={newCourse.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            
              <Form.Group controlId="formDuration">
                <Form.Label>Duration (weeks)</Form.Label>
                <Form.Control
                  type="number"
                  name="duration"
                  value={newCourse.duration}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMinAge">
                <Form.Label>Min Age</Form.Label>
                <Form.Control
                  type="text"
                  name="minage"
                  value={newCourse.minage}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMinAge">
                <Form.Label>Max Age</Form.Label>
                <Form.Control
                  type="text"
                  name="maxage"
                  value={newCourse.maxage}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMinAge">
                <Form.Label>Price (GHC)</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={newCourse.price}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formProgramId">
                <Form.Label>Program ID</Form.Label>
                <Form.Select
                  name="programId"
                  value={newCourse.programId}
                  onChange={handleInputChange}
                  required
                >
                  {Object.entries(programId).map(([key, value]) => (
                    <option key={value} value={value}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </Body>
  );
};

export default Courses;
