import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col,Button, Spinner } from 'react-bootstrap';

import ViewModal from '../../modal/viewModal';
import AddUserForm from '../../Component/Forms/addUserForm'
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import SessionCard from '../../Component/SessionCard';
import { courses, programId } from '../../coursedata';
import SessionDivs from './SessionDivs';


 function ThreeDSessions() {
  const [loading,setLoading] = useState(false);
  const [sect, setSect] = useState()
  const [showMoreVR, setShowMoreVR] = useState(false); // For "Read More" in VR Development section

  useEffect(()=>{
      const fetchData =async()=>{
        setLoading(true)

      let classesdata = []
      const q = query(collection(db, "courses"), where("programId", "==", programId.threeD));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      setLoading(false)
      }

        fetchData();
  },[])


  
  return(
        
<section className="sessions pricing threed   py-5 bg-light ">
  
<div className="container row mx-auto">
  <div className="heading text-end">
  <span class="badge  bg-info badge-warning badge-pill ">New</span>
  <h1 className="title text-end text-dark" id="pricing">3D Modeling + 3D Printing</h1>
          <div className="separator"></div>
           
            <br/>	
          </div> 
    <div className="row">
    
    <div className='col-md-4 m-auto '>

        {
          sect && sect.map ( (element, i ) => {
            return   <SessionDivs className="bg-transparent" type="threed" loading  sect title=''/>
            })
        }
        {
            (loading && !sect)   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              <p>Loading Available Sessions</p>
              </div>

        }
    </div>
    <div className='col-md-6 text-end mx-0  ' >
        <p className='text- lead text-dark mb-2'>
        Unleash your child's creativity and bring their ideas to life!        </p>
        <p className='text- lead text-dark mb-2'>
        Our 3D Modelling and 3D Printing Program introduces kids to the exciting world of 3D design and printing.         </p>
        {showMoreVR && (
        <p className='text- lead text- mb-2'>
        Using cutting-edge software and technology, students will learn to design, model, and print their own innovative creations, developing essential skills in STEM, critical thinking, and problem-solving.       
        <br/>
        </p>
         )}
        <button
                className="btn btn-dark text-white "
                onClick={() => setShowMoreVR(!showMoreVR)}
              >
                {showMoreVR ? "Read Less" : "Read More"}
              </button>
    </div>  
    </div>
  </div>



</section>
    );
}
export default ThreeDSessions;












