export const programId={
    robotics:"dulxLFK5unfkodw3P5pw",
    coding:"aSsawjF1UaNwPXLo0f8E",
    dronePiloting:"b1k9n0xANe52ewXiatbU",
    vr:"NMfw8EGnx3P2J0TTxByH",
    vrdc:"K1TrnrcUiAvbRR2GrbSi",
    threeD:"MZ0N8cqhqMPcsH67c04V",
    electronics:"aVm0ibq3MI4tfE27BrF9",
}
export const courses = {
python : {
    price: 2000,
    duration: 16,
    name: "Python Programming",
    minAge: 10,
    programId:programId.coding,
    new: false, 

},
dronePiloting : {
    price: 1200,
    duration: 8,
    name: "Drone coding & Piloting",
    minAge: 10,
    maxAGe:16,
    programId:programId.dronePiloting ,
    new: false,

},
vr : {
    price: 20,
    duration: 1,
    name: "Virtual Reality Experience",
    minAge: 10,
    maxAGe:16,
    programId:programId.vr ,
    new: false,

},
vrdc : {
    price: 1200,
    duration: 8,
    name: "VR Development / Coding",
    minAge: 10,
    maxAGe:16,
    programId:programId.vrdc,
    new: true,
},
electronics : {
    price: 1200,
    duration: 8,
    name: "Basic Electronics (Arduino)",
    minAge: 8,
    maxAGe:16,
    programId:programId.electronics,
    new: true,
},
threed : {
    price: 1600,
    duration: 8,
    name: "3D Modeling + Printing ",
    minAge: 10,
    maxAGe:16,
    programId:programId.threeD,
    new: true,

},
 webdev :{
    price: 2000,
    duration: 16,
    name: "Web Design (HTML + CSS + Javascript)",
    minAge: 9,
    programId:programId.coding ,
    new: false,
},
roboticsSenior :{
    price: 1200,
    duration: 8,
    name: "Senior Robotics (9+)",
    minAge: 9,
    programId:programId.robotics,
    new: false, 
},
roboticsJunior :{
    price: 1000,
    duration: 8,
    name: "Junior Robotics (5+)",
    minAge: 5,
    programId:programId.robotics ,
    new: false,
},
hub :{
    price: 400,
    programId:programId.robotics ,
    new: false,
}

}